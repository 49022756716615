import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import "./solid-hero.sass"
import Img from "gatsby-image"

const SolidHero = ({ title, subtitle, primary, imageFluid, imageAlt }) => {

  return <div
    className={classNames("_8a", { "_8e": primary }, { "_8g": imageFluid && imageAlt })}>
    <div className={"_8h"}>
      <div className="_15">


      <h1 className={"_8j"}>{title}</h1>
      <h2 className={"_4j _4j _8i"}>{subtitle()}</h2>
      </div>
    </div>
      {imageFluid && imageAlt &&
      <div className={'_8k'}>

        <div className="_15">

        <Img imgStyle={{ "objectFit": "contain" }} className={"_8l"}
             fluid={imageFluid} alt={imageAlt}></Img>
        </div>
        </div>
      }
  </div>
}

SolidHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.func.isRequired,
  imageFluid: PropTypes.any,
  imageAlt: PropTypes.string,
  primary: PropTypes.bool,
}

export default SolidHero
